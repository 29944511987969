<template>
  <div class="d-inline-block">
    <CButton class="d-inline-block" color="primary" @click="castModal = true; resetCastModalData();">
      <i class="fab fa-chromecast"/>
    </CButton>

    <b-modal class="cast" :can-cancel="[]" :active.sync="castModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('signage.Cast_your_screen')}}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CButton color="primary" @click="selectReceiverDevice();">{{$t('signage.Start_cast_session')}}</CButton>
            </CCol>
            <!-- <CCol cols="12" lg="12" class="pt-0">
              <CSelect :label="$t('signage.Signage_type')" class="mb-0" :value.sync="castModalData.signage_type" :options="signageTypes" :placeholder="$t('signage.Select_signage_type')"/>
            </CCol> -->
            <!-- <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CInput type="text" :label="$t('signage.Signage_url')" class="mb-0" v-model="castModalData.signage_url"/>
            </CCol> -->
          </CRow>                             
        </CCardBody>
        <CCardFooter>
          <div class="text-left">
            <CButton color="secondary" @click="castModal = false; resetCastModalData();"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
          </div>
          <div class="text-right">
            <CButton color="primary" @click="castScreen();">{{$t('Send')}}<i class="fas fa-chevron-right ml-1"/></CButton>
          </div>
        </CCardFooter>          
      </CCard>
    </b-modal> 
  </div>
</template>

<script>

var applicationID = '5CB45E5A';
var namespace = 'urn:x-cast:com.url.cast'
var receiverDead = false
var session = null;

// Initialize
initializeCastApi();
/* window.__onGCastApiAvailable = function(loaded) {
  if (loaded) initializeCastApi();
}; */

function initializeCastApi() {
  // Create a new sessionRequest
  var sessionRequest = new chrome.cast.SessionRequest(applicationID);
  // Create a new apiConfig
  var apiConfig = new chrome.cast.ApiConfig(sessionRequest, sessionListener, receiverListener);
  // Initialize
  chrome.cast.initialize(apiConfig, onSuccess.bind(this, 'Initialized'), onError);
}

function onError(error) {
  console.log('Error: ' + JSON.stringify(error));
}

function onSuccess(message) {
  console.log('Success: ' + message);
}

function sessionListener(newSession) {
  console.log('New session ID:' + newSession.sessionId);
  session = newSession;
  session.addUpdateListener(sessionUpdateListener);
  session.addMessageListener(namespace, receiveMessage);
}

function receiverListener(e) {
  (e === 'available') ? console.log('Receiver found') : console.log('No receivers found');
}

function sessionUpdateListener(isAlive) {
  if(!isAlive) session = null;  
  console.log('Session is alive?: ', isAlive);
}

function receiveMessage(namespace, message) {
  console.log('Receiver said: ' + message);
}

function sendMessage(message) {
  if(receiverDead || !session) return;  
  // Send a message
  session.sendMessage(namespace, message, function() { console.log('Message sent: ' + message); }, onError);

  if(message.type === 'loc') {
    receiverDead = true;
    console.log('Note: Receiver will now be unresponsive');
  }
}

export default {
  name: 'Caster',
  props: ['screenData'],
  data() {
    return {
      companyIdExternal: null,
      castModal: false,
      castModalData: {},
      signageTypes: [ 'iframe', 'loc' ]
    }
  },
  methods: {
    resetCastModalData() {
      this.castModalData.signage_type = 'loc';            
      this.castModalData.signage_url = process.env.VUE_APP_SIGNAGE_URL + '/#/signage/live?company=' + this.companyIdExternal + '&screen=' + this.screenData.signage_screen_id_external + '&code=' + this.screenData.pairing_code;
    },
    selectReceiverDevice() {
      chrome.cast.requestSession(sessionListener, onError);
    },
    castScreen() {
      let signageType = this.castModalData.signage_type;
      let signageUrl = this.castModalData.signage_url;

      if(signageType && signageUrl) {
        sendMessage({type: signageType, url: signageUrl});
        
        this.castModal = false;
        this.resetCastModalData();
      } else {
        return;
      }
    }    
  },
  mounted: function(){
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
  }
}
</script>