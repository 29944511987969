<template>
  <CRow class="tickerMessages">
    <CCol cols="12" lg="12" md="12" class="pt-0" v-bind:class="{'pb-0' : tickerMessages.length <= perPage}">
      <CRow class="m-0 actions">
        <CCol cols="12" xl="12" class="pt-0 pb-0">
          <div class="pt-2 pb-2 d-flex align-items-center justify-content-end">
            <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getTickerMessages()">{{$t('common.show_inactive')}}</b-switch>
            <div v-if="checkPermission('connect.signage.tickeraddedit')" class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="ml-2 mr-0 link" color="primary" @click="openSidebarRight('ticker_message', { signage_ticker_message_id_external: null });">
                <i class="fas fa-plus mr-1"/>
                <span>{{ $t('signage.Add_ticker_message') }}</span>
              </CButton>
            </div>             
          </div>
        </CCol>
      </CRow>
      <hr class="m-0"/>
      <CRow>
        <CCol cols="12" lg="12" class="pb-0">
          <b-table ref="tickerMessageTable"
                   class="data_table"
                   :data="tickerMessages"
                   :striped="true"
                   :hoverable="true"
                   :mobile-cards="true"
                   :paginated="isPaginated"
                   :per-page="perPage"
                   :current-page.sync="currentPage"
                   :pagination-simple="isPaginationSimple"
                   :pagination-position="paginationPosition"                  
                   :draggable="checkPermission('connect.signage.tickeraddedit')"
                   @dragstart="dragStepstart"
                   @drop="dropStep"
                   @dragover="dragStepover"
                   @dragleave="dragStepleave">        
            <template slot-scope="props">
              <b-table-column field="move" label="" :visible="checkPermission('connect.signage.tickeraddedit')">
                <i class="fas fa-arrows-alt pointer"/>
              </b-table-column>
              <b-table-column field="message_order" :label="$t('Order')" width="2%" :visible="checkPermission('connect.signage.tickeraddedit')">
                <span>{{props.row.message_order + 1}}</span>
              </b-table-column>
              <b-table-column field="ticker_message" :label="$t('signage.Ticker_message')" :searchable="searchEnabled" width="75%">
                <quick-edit v-if="checkPermission('connect.signage.tickeraddedit')" type="textarea" mode="ignore" :buttonOkText="$t('Update')" :buttonCancelText="$t('cancel')" v-model="props.row.ticker_message" v-on:input="updateTickerMessage(props.row.signage_ticker_message_id_external, props.row);">
                  <span v-line-clamp="1">{{props.row.ticker_message}}<i class="icon edit fas fa-pen ml-1"/></span>
                </quick-edit>
                <span v-else v-line-clamp="1">{{props.row.ticker_message}}</span>
              </b-table-column>
              <b-table-column field="actions" :visible="checkPermission('connect.signage.tickeraddedit')">
                <div class="d-flex justify-content-lg-end">
                  <b-switch class="mb-0 mr-2" v-model="props.row.is_active" size="is-small" @input="updateTickerMessage(props.row.signage_ticker_message_id_external, props.row);">{{ $t('common.Active') }}</b-switch>
                  <!-- <CButton class="d-inline-block" color="primary" @click="openSidebarRight('ticker_message', { signage_ticker_message_id_external: props.row.signage_ticker_message_id_external })">
                    <i class="fas fa-pen"/>
                  </CButton> -->
                  <CButton class="m-0 d-inline-block" color="primary" @click="removeTickerMessage(props.row.signage_ticker_message_id_external)">
                    <i class="fas fa-trash"/>
                  </CButton>
                </div>
              </b-table-column>
            </template>
            <template slot="empty">
              <div class="p-2 text-center">
                <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('signage.Loading_ticker_messages')"/>
                <span v-else>{{ $t('signage.No_ticker_messages_found') }}</span>
              </div>                
            </template>                
          </b-table>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'Ticker',
  props: ['platformPermissions'],
  components: {
    loadingSpinner,
    QuickEdit
  },
  data() {
    return {
      tickerMessages: [],
      overviewDataLoaded: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      searchEnabled: true,
      showInactive: false
    }
  },
  methods: {   
    getTickerMessages () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/messages')
      .then(res => {
        this.tickerMessages = res.data.data;

        if(!this.showInactive) {
          this.tickerMessages = this.tickerMessages.filter( i => ['Y'].includes( i.active ) );
        }        
        // Check if the pagination should be activated
        (this.tickerMessages.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false; 
        // Update the overviewDataLoaded value        
        this.overviewDataLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },   
    updateTickerMessage (id, data) {
      let tickerMessageIdExternal = id;
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message/' + tickerMessageIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('signage.Ticker_message_updated'), type: 'is-success', duration: 2000 });
        // Get the messages
        this.getTickerMessages();          
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })         
    },
    removeTickerMessage(id) {
      let tickerMessageIdExternal = id;
            
      axios.delete(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message/' + tickerMessageIdExternal)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('signage.Content_removed'), type: 'is-success', duration: 2000 });
        // Get the messages
        this.getTickerMessages();
      })
      .catch(err => {
        // console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },
    getTickerMessageDetails (id) {
      let tickerMessageIdExternal = id;

      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message/' + tickerMessageIdExternal)
      .then(res => {
        let updatedTickerMessage = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.tickerMessageTable !== undefined ) {
          // Get the message index
          let messageIndex = this.$refs.tickerMessageTable.data.findIndex(x => x.signage_ticker_message_id_external == updatedTickerMessage.signage_ticker_message_id_external);
          // Update the is active value for the row
          this.$refs.tickerMessageTable.data[messageIndex].is_active = updatedTickerMessage.is_active;
          this.$refs.tickerMessageTable.data[messageIndex].ticker_message = updatedTickerMessage.ticker_message;
        }    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateTickerMessageOrder (data) {
      let params = {}
      params = data;
                
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message/order', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('signage.Ticker_message_order_updated'), type: 'is-success', duration: 2000 });
        // Update the ticker messages
        this.getTickerMessages();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },         
    dragStepstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
    },
    dragStepover (payload) {
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragStepleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    dropStep (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.index    
      this.draggingRow.new_message_order = droppedOnRowIndex;
      this.draggingRow.old_message_order = this.draggingRowIndex;      
      this.updateTickerMessageOrder(this.draggingRow);
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function(){
    this.getTickerMessages();

    this.$bus.$on('update_ticker_messages', () => {
      this.getTickerMessages();
    });

    this.$bus.$on('update_ticker_message_details', (tickerMessageIdExternal) => {
      this.getTickerMessageDetails(tickerMessageIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_ticker_messages');
    this.$bus.$off('update_ticker_message_details');
  }
}
</script>