<template>
  <CRow v-if="platformPermissionsLoaded && (checkPermission('connect.signage.overview') || checkPermission('connect.signage.tickeroverview'))" class="signage">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0 text-left">
              <span>{{ $t('signage.Signage') }}</span>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="has_tabs pt-0 pb-0">
          <CRow class="pb-0">
            <CCol cols="12" lg="12" class="p-0">
              <b-tabs class="signage_tabs no_borders mb-0" type="is-boxed" :animated="false" v-model="activeTab">
                <b-tab-item v-if="checkPermission('connect.signage.overview')">
                  <template slot="header">
                    <i class="mr-1 fab fa-chromecast"/><span>{{ $t('signage.Screens') }}</span>
                  </template>
                  <template>
                    <Screens :platformPermissions="platformPermissions"/>
                  </template>
                </b-tab-item>
                <b-tab-item v-if="checkPermission('connect.signage.tickeroverview')">
                  <template slot="header">
                    <i class="mr-1 fas fa-comment-alt"/><span>{{ $t('signage.Ticker') }}</span>
                  </template>
                  <template>
                    <Ticker :platformPermissions="platformPermissions"/>
                  </template>
                </b-tab-item>
              </b-tabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import noPermission from '@/components/common/noPermission.vue';
import Screens from '@/views/connect/signage/screens/Screens.vue';
import Ticker from '@/views/connect/signage/ticker/Ticker.vue';

export default {
  name: 'Overview',
  components: {
    noPermission,
    Screens,
    Ticker
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      activeTab: 0
    }
  },
  methods: {
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    } 
  }, 
  mounted: function() {
    this.getPlatformPermissions();
  }
}
</script>